import http from '@/common/axios/http.js'
// import store from '@/store'
import VueCookies from "vue-cookies";



function getCurrentAuth() {
    let api = "";
    let auth = VueCookies.get("auth");
    if (auth == 1) {
        api = "/api"; //塔吊
    } else if (auth == 2) {
        api = "/dianti"; 
    }
    return api;
}

export function systemLogin(data) {
    let api = getCurrentAuth();
    return http.post(api + "/Login", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}

// //获取已注册站点列表
// export function GetStationInfo() {
//     return http.get("/api/GetStationInfo", {
//         headers: {
//             // "tckw-cookie": store.state.sessionid,
//         },
//     });
// }
//获取已注册站点列表



/**
 * 获取所有站点
 * @returns 
 */
export function GetStationInfo() {
    let api = getCurrentAuth();
    return http.get(api + "/GetStationInfo", {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}


/**
 * 获取所有站点实时状态
 * @returns 
 */
export function GetStationState() {
    let api = getCurrentAuth();
    return http.get(api + "/GetStationState", "", {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}


/**
 * 
 * 获取单个站点的实时状态
 * @returns 
 */
export function GetStState(data) {

    let api = getCurrentAuth();
    return http.post(api + "/GetStState", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}

/**
 * 获取单个站点的损伤数据
 * 
 * @returns 
 */
export function GetStationFlawData(data) {
    let api = getCurrentAuth();
    return http.post(api + "/GetStationFlawData", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}

/**
 * 获取单个站点的损伤数据
 * 
 * @returns 
 */
 export function GetSingleAlarmLog(data) {
    let api = getCurrentAuth();
    return http.post(api + "/GetSingleAlarmLog", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}


/**
 * 获取单个站点的统计数据
 * 
 * @returns 
 */
export function GetStationStatistics(data) {
    let api = getCurrentAuth();
    return http.post(api + "/GetStationStatistics", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}


/**
 * 
 * 获取历史波形数据
 * @returns 
 */
export function GetHistoryCurve(data) {
    let api = getCurrentAuth();
    return http.post(api + "/GetHistoryCurve", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}

/**
 * 
 * 获取最新波形数据
 * @returns 
 */
export function GetSingleCurve(data) {
    let api = getCurrentAuth();
    return http.post(api + "/GetSingleCurve", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}

/**
 * 
 * 获取单个站点历史日统计数据
 * @returns 
 */

export function GetStationFlawDaily(data) {
    let api = getCurrentAuth();
    return http.post(api + "/GetStationFlawDaily", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}



/**
 * 
 * 获取站点单个损伤的量值发展趋势数据
 * @returns 
 */
export function GetStationFlawTrend(data) {
    let api = getCurrentAuth();
    return http.post(api + "/GetStationFlawTrend", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}


/**
 * 
 * 获取站点单个损伤的量值发展趋势数据
 * @returns 
 */
 export function GetSingleFlawData(data) {
    let api = getCurrentAuth();
    return http.post(api + "/GetSingleFlawData", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}

/**
 * 
 * 获取站点单个损伤的量值发展趋势数据
 * @returns 
 */
 export function GetRopeInfo(data) {
    let api = getCurrentAuth();
    return http.post(api + "/GetRopeInfo", JSON.stringify(data), {
        headers: {
            // "tckw-cookie": store.state.sessionid,
        },
    });
}


