<template>
  <div class="lang-container">
    <el-dropdown trigger="click" @command="handleCommand">
      <span class="el-dropdown-link">
        <el-icon class="el-icon--right">
          <img src="@/assets/icon/lang.png" class="lang" alt="" />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu class="item">
          <el-dropdown-item command="zh-CN"
            ><img src="@/assets/icon/china.png" alt="" class="flag" />
            Chinese</el-dropdown-item
          >
          <el-dropdown-item command="en"><img src="@/assets/icon/english.png" alt="" class="flag" /> English</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
const store = useStore();
const handleCommand = (lang) => {
  store.commit("changLangState", lang);
};
</script>

<style lang="scss" scoped>
.lang-container {
  color: #fff;
  .el-dropdown-link {
    cursor: pointer;
    font-size: 32px;
    color: #fff;
    .lang {
      width: 0.5rem;
    }
  }
}
.flag {
    width: 30px;
    margin-right: .0625rem;
  }
</style>