import axios from 'axios'
import {
  ElMessage
} from "element-plus";
import router from '@/router'
import VueCookies from 'vue-cookies'
import utils from '@/common/utils.js'
import env from '../../env.js'
// axios 设置默认配置
const http = axios.create({
  //baseURL: '',
  // timeout: 5000,
  headers: { //
    'Content-Type': 'application/json;charset=UTF-8',
    "jwt-token": '', // 这里全局配置只加载一次   刷新页面只执行一次
  },
  // transformRequest: [
  //   function(data, headers) {
  //     headers.token = store.state.token
  //     if (headers['Content-type'] === 'multipart/form-data') {
  //       return data
  //     } else {
  //       return JSON.stringify(data)
  //     }
  //   }
  // ]
})


//在请求之前做处理  请求拦截器
http.interceptors.request.use(async function (config) {
  //比如是否需要设置 token
  //config.headers['jwt-token']= VueCookies.get('jwtToken');
  if (config.url.indexOf("/Login") == -1) {
    let tckw_cookie = VueCookies.get("TCKWSESSID");
    if (!tckw_cookie) {
      tckw_cookie = await utils.getNewTckwCookie();

    }
    config.headers['tckw-cookie'] = tckw_cookie
  }

  return config;
})

//响应拦截去  可以设置全局异常处理
http.interceptors.response.use(response => {

  // 对响应数据做些什么
  const tckw_cookie = response.headers['tckw-cookie'];
  VueCookies.set("TCKWSESSID", tckw_cookie, env.authExpireTime);
  return response
}, err => {
  // 对响应错误做些什么
  console.log(err)
  if (err.response.status == 401 || err.response.status == 404) {

    VueCookies.remove("TCKWSESSID");

    ElMessage({
      message: "服务端session过期  请刷新页面退出重新登录",
      type: "error",
      duration: 2000,
      onClose: () => {
        router.push({
          name: "login",
        });
      },
      offset: 300,
    });

    //也可以使用elementui的消息提示
    //ElementUI.Message(i18n.t('error.permission'));
  } else if (err.response.status == 500) {
    VueCookies.remove("TCKWSESSID");
    ElMessage({
      message: "服务器掉线，请稍后重新再登录",
      type: "error",
      duration: 2000,
      onClose: () => {
        router.push({
          name: "login",
        });

      },
      offset: 300,
    });
  } else {
    //其他情况，是业务的错误，直接返回业务逻辑错误
    console.log('err', err);

    return Promise.reject(err); //Promise.resolve(err.sresponse);可在组件内获取到服务器返回信息
  }
})

export default http