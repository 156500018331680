<template>
  <div class="soft-container">
    <div class="soft-table-box">
      <el-table
        :data="softList"
        @selection-change="handleSelectChange"
        border
        ref="tableRef"
        style="width: auto"
        height="600px"
      >
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column type="index" label="序号" width="55" />
        <el-table-column prop="softName" label="软件名称" min-width="200" />
        <el-table-column prop="softType" label="软件类型" min-width="180" />
        <el-table-column prop="version" label="版本号" min-width="180" />
        <el-table-column prop="uploadDate" label="上传日期" min-width="180" />

        <el-table-column fixed="right" label="操作" min-width="100">
          <template #default="scope">
            <el-button
              type="success"
              size="small"
              @click="downloadSoft(scope.row)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, reactive } from "vue";

const tableRef = ref(null);
const softListJson = window.config.softData;
const softList = reactive(softListJson);

const downloadSoft = () => {};

onMounted(() => {
  nextTick(() => {});
});
</script>


<style lang="scss" scoped>
.soft-table-box {
  width: 95%;
  margin: 0 auto;
  background-color: #fff;
}
</style>