import { createStore } from 'vuex'
import mutations from './mutations'
import actions from './action'
import langCn from "@/lang/zh_cn/index.json";
export default createStore({
  state: {
    sessionid: '', //每次请求所有接口都会返回新的sessionid,
    closingPage:"", //缓存的标签页
    auth:0,
    newTimes:0, //站点最新一次时间戳
    lang:{
      "lang":"zh-CN",
      "list":langCn
    },
    worktab: {
      list: [{
        name: 'wechatCategory',
        tabname: '栏目列表(wechat)',
        path: '/wechatCategory',
      }],
      current: {
        name: 'wechatCategory',
        tabname: '栏目列表(wechat)',
        path: '/wechatCategory',
      }
    },

  },
  mutations,
  actions
})
