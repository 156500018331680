import {
    ElAside,
    ElContainer,
    ElHeader,
    ElMain,
    ElButton,
    ElInput,
    ElAffix,
    ElDialog,
    ElImage,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    ElMessage,
    ElPagination,
    ElMenu,
    ElSubMenu,
    ElMenuItemGroup,
    ElMenuItem,
    ElIcon,
    ElRow,
    ElCol,
    ElScrollbar,
    ElCheckboxGroup,
    ElCheckbox,
    ElCollapse,
    ElCollapseItem,
    ElTable,
    ElTableColumn,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,

} from 'element-plus';

import 'element-plus/dist/index.css'

export function element(app) {    
    app.use(ElButton);
    app.use(ElInput);
    app.use(ElAffix);
    app.use(ElDialog);
    app.use(ElImage);
    app.use(ElForm);
    app.use(ElFormItem);
    app.use(ElSelect);
    app.use(ElOption);
    app.use(ElMessage);
    app.use(ElPagination);
    app.use(ElAside);
    app.use(ElContainer);
    app.use(ElHeader);
    app.use(ElMain);
    app.use(ElMenu);
    app.use(ElSubMenu);
    app.use(ElMenuItemGroup);
    app.use(ElMenuItem);
    app.use(ElIcon);
    app.use(ElRow);
    app.use(ElCol);
    app.use(ElScrollbar);
    app.use(ElCheckboxGroup);
    app.use(ElCheckbox);
    app.use(ElCollapse);
    app.use(ElCollapseItem);
    app.use(ElTable);
    app.use(ElTableColumn);
    app.use(ElDropdown);
    app.use(ElDropdownMenu);
    app.use(ElDropdownItem);
}