// import router from '@/router'
import langCn from "@/lang/zh_cn/index.json"; //中文语言包
import langEng from "@/lang/en/index.json"; //英文语言包

export default {
  saveUserName(state, username) {
    state.username = username;
  },
  savePassWord(state, password) {
    state.password = password;
  },

  saveElevatorsState(state, elevatorsState) {
    state.elevatorsState = elevatorsState;
  },

  saveElevatorsInfo(state, elevatorsInfo) {
    state.elevatorsInfo = elevatorsInfo;
  },

  saveSessionidInfo(state, sessionid) {
    state.sessionid = sessionid;
  },

  saveStationAuth(state, auth) {
    state.auth = auth;
  },
  saveNewTimes(state,times){
    state.newTimes = times;
  },
  //设置当前系统的语言包
  changLangState(state, lang) {

    let langObj = {};
    switch (lang) {
      case "zh-CN":
        document.getElementsByTagName("body")[0].style.fontFamily = "Microsoft Yahei"
        langObj = {
          "lang": lang,
          "list": langCn
        };

        if (window.config.is_tckw == 1) {
          langObj.list.login.title = window.config.title_tckw;
        }else{
          langObj.list.login.title = window.config.title_cus;
        }


        break;
      case "en":
        document.getElementsByTagName("body")[0].style.fontFamily = "Arial"
        langObj = {
          "lang": lang,
          "list": langEng
        };
        break;
    }

    state.lang = langObj;
  },

  worktabRoute(state, p) {
    let ind = state.worktab.list.findIndex(s => s.name === p.to.name)
    if (ind > -1) {
      // 标签已存在
      state.worktab.current = state.worktab.list[ind]
    } else {
      // 标签不存在，现在新建
      state.worktab.list.push(p.to)
      state.worktab.current = p.to
    }
    state.closingPage = ''
  },

  worktabRemove(state, p) {
    // 关闭标签
    let ind = state.worktab.list.findIndex(s => s.name === p)
    console.log(state.worktab.list)
    if (ind > -1) {
      // 清理 keep alive - start
      state.closingPage = state.worktab.list[ind].name
      // 清理 keep alive - end
      state.worktab.list.splice(ind, 1)
    }
    if (p === state.worktab.current.name) {
      // 是当前页，返回上一页
      //router.back()
    }
  },


}