import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import VueCookies from "vue-cookies";
import Home from '../views/Home.vue'
import store from '@/store'
// import {
//   ElMessage
// } from "element-plus";
const routes = [{
    path: '/',
    name: 'Home',
    //  redirect: '/login',
    meta: {
      keepAlive: false,
      isBottom: true,
    },
    component: Home
  },

  {
    path: '/login',
    name: 'login',
    meta: {
      keepAlive: false,

    },
    component: () => import('../views/Login.vue')
  },

  // {
  //   path: '/product/:id',
  //   name: 'product',
  //   meta: {
  //     keepAlive: false,
  //     isBottom: true,

  //   },
  //   component: () => import('../views/product/product.vue')
  // },

  {
    path: '/detail/:id',
    name: 'detail',
    meta: {
      keepAlive: false,
      isBottom: true,
    },
    component: () => import('../views/detail/detail.vue')
  },
  {
    path: '/echarts',
    name: 'echarts',
    meta: {
      keepAlive: false,
      isBottom: true,
    },
    component: () => import('../views/echarts/echarts.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

//全局路由守卫
router.beforeEach((to, from, next) => {

  if (store.state.auth == null) {
    let auth = VueCookies.get('auth');
    store.commit('saveStationAuth', Number(auth));
  }

  var TCKWSESSID = VueCookies.get('TCKWSESSID');

  if (TCKWSESSID != null) {
    //  store.commit('saveSessionidInfo', TCKWSESSID); //存储到vue内部当中后面每个接口都要从内存获取
    if (to.path == '/login') {
      next('/')
    }
  }

  if (!TCKWSESSID) {
    if (to.path != '/login') {
      
      next('/login')
      // ElMessage({
      //   message: "登录超时了~~请刷新页面退出重新登录",
      //   type: "error",
      //   duration: 2000,
      //   onClose: () => {
      //     //next('/login')
      //     if (!VueCookies.get('TCKWSESSID')) {
      //       router.push({
      //         name: "login",
      //       });
      //     }

      //   },
      //   offset: 300,
      // });


    }
  }

  next();
  // const unTabs = ['goodsList', 'wechatCategory', 'itemInfo', 'abcDetail'];
  // if (!unTabs.includes(to.name)) {
  //   store.commit('worktabRoute', {
  //     to: {
  //       name: to.name ? to.name : '',
  //       tabname: (to.meta) ? to.meta[1] : '',
  //       path: to.path
  //     },
  //     from: {
  //       name: from.name ? from.name : '',
  //       tabname: (from.meta) ? from.meta[1] : '',
  //       path: from.path
  //     }
  //   })
  // }



});
export default router