import {
    computed,
    reactive
} from "vue";
import {
    GetStationInfo,
    GetStationState
} from "@/common/api/index/api.js";

//计算属性的写法 
export function filterRingBackGroundHook() {
    const filterRingBackGround = computed(() => (state) => {
        switch (state) {
            case 0:
                return "offline-bd";
            case 1:
                return "safe-bd";
            case 2:
                return "warning-bd";
            case 3:
                return "danger-bd";
            case 4:
                return "fault-bd";
            default:
                break;
        }
        return "";
    });
    return filterRingBackGround
}

export function filterSystemSafeColorHook() {
    const filterRingColor = computed(() => (state) => {
        switch (state) {
            case 0:
                return "offline";
            case 1:
                return "safe";
            case 2:
                return "warning";
            case 3:
                return "danger";
            case 4:
                return "fault";
            default:
                break;
        }
        return "";
    })
    return filterRingColor
}



export function filterSyetemSafeStateHook(state, lang) {
    if (lang == "zh-CN") {
        switch (state) {
            case 0:
                return "离线";
            case 1:
                return "安全";
            case 2:
                return "预警";
            case 3:
                return "报警";
            case 4:
                return "故障"
            default:
                break;
        }
    } else if (lang == "en") {
        switch (state) {
            case 0:
                return "offline";
            case 1:
                return "safe";
            case 2:
                return "warning";
            case 3:
                return "Alarm";
            case 4:
                return "fault"
            default:
                break;
        }
    }

}


export function filterSystemRunStateHook(state, lang) {
    if (lang == "zh-CN") {
        switch (state) {
            case -1:
                return "未上线";
            case 0:
                return "停止";

            case 2:
                return "空域检测";

            case 3:
                return "时域检测"
            case 5:
                return "标定中"

            default:
                break;
        }
    } else if (lang == "en") {
        switch (state) {
            case -1:
                return "not online";
            case 0:
                return "stop";

            case 2:
                return "airspace scan";

            case 3:
                return "TD detection"
            case 5:
                return "calibrating"

            default:
                break;
        }
    }

}

export function filterSystemRunStateColorHook(state) {
    switch (state) {
        case -1:
            return "outling";
        case 0:
            return "stop";

        case 2:
            return "kongyu";

        case 3:
            return "shiyu"
        case 5:
            return "biaoding"

        default:
            break;
    }
}

export function filterSystemRunDirectionHook(state, runimg) {
    switch (state) {
        case 0:
            return runimg.stop
        case 1:
            return runimg.up
        case 2:
            return runimg.down
        default:
            break;
    }
}

export async function httpGetStationInfoHook(single = false, id = 0) {
    const stationInfoList = reactive([]);
    const res = await GetStationInfo();
    const infoList = res.data.info;
    for (let i = 0; i < infoList.length; i++) {
        const tempArr = {};
        tempArr.id = infoList[i][0]; //站点ID
        tempArr.stationType = infoList[i][1]; //站点类型
        tempArr.stationName = infoList[i][2]; // 站点名称
        tempArr.motorRoomName = infoList[i][3]; //楼宇名称
        tempArr.buildingName = infoList[i][4]; //机房名称
        tempArr.ropeCount = infoList[i][5]; //钢丝绳数量
        tempArr.ropeLenght = infoList[i][6]; //钢丝绳长度
        tempArr.ropeDiameter = infoList[i][7]; //钢丝绳长度
        tempArr.ropeSpecs = infoList[i][8]; //钢丝绳规格
        tempArr.beltWidth = infoList[i][9]; //皮带宽度
        tempArr.beltJoinCount = infoList[i][10]; //皮带接头数
        tempArr.safeState = infoList[i][11]; //安全状态
        tempArr.isHandleState = infoList[i][12]; //预警/报警是否已处理
        tempArr.realSpeed = infoList[i][13]; // 实时速度，单位m/s
        tempArr.runDirection = infoList[i][14]; //运行方向
        tempArr.realSite = infoList[i][15]; //实时位置
        tempArr.currentfLoor = infoList[i][16]; //当前所在楼层
        tempArr.fLoorAllCount = infoList[i][17]; //总楼层数
        tempArr.testCoverageRate = infoList[i][18]; //当日监测覆盖率，百分比
        tempArr.syetemRunState = infoList[i][19]; //系统运行状态
        tempArr.installDate = infoList[i][20]; // 安装日期
        tempArr.joinNumber = infoList[i][21]; // 接头编号
        tempArr.gripperNumber = infoList[i][22]; // 接头编号
        tempArr.dayRunCount = infoList[i][23]; // 日运行总次数
        tempArr.dayRunTime = infoList[i][24]; // 日运行总时长
        tempArr.dayRunRange = infoList[i][25]; // 日运行总里程
        tempArr.stationSn = infoList[i][26] //站点序列号
        tempArr.lastTestLength = infoList[i][27] //最新一次检测长度
        tempArr.MaxTestLength = infoList[i][28] //最大一次检测长度
        stationInfoList.push(tempArr);
    }

    if (single) return stationInfoList.find(v => v.id == id);

    return {
        stationInfoList
    };
}

export async function httpGetStationStateHook(stationInfoArr) {
    const stationStateList = [];
    const res = await GetStationState();
    const stStateList = res.data.state;
    for (let i = 0; i < stStateList.length; i++) {
        const stationInfo = stationInfoArr.find(v => v.id == stStateList[i][0]);
        const tempObj = {};
        tempObj.id = stStateList[i][0]; //站点ID
        tempObj.safeState = stStateList[i][1]; //安全状态
        tempObj.syetemRunState = stStateList[i][9]; // 运行状态
        tempObj.runDirection = stStateList[i][4]; //运行方向
        tempObj.realSpeed = stStateList[i][3]; //实时速度
        tempObj.realSite = stStateList[i][5]; //实时位置
        tempObj.MaxTestLength = stStateList[i][16]; // 周期内最大检测长
        tempObj.dayRunRange = stStateList[i][14] // 日运行总里程
        tempObj.dayRunTime = stStateList[i][13] // 日运行总时长

        tempObj.ropeDiameter = stationInfo.ropeDiameter // 绳直径
        tempObj.ropeCount = stationInfo.ropeCount; //绳数量
        tempObj.ropeSpecs = stationInfo.ropeSpecs; //绳规格
        tempObj.stationName = stationInfo.stationName; // 站点名称
        tempObj.motorRoomName = stationInfo.motorRoomName; //楼宇名称
        tempObj.buildingName = stationInfo.buildingName; //机房名称


        stationStateList.push(tempObj);
    }
    return stationStateList;
}


//普通函数的写法
export function filterRingBackGround1(state) {

    switch (state) {
        case 0:
            return "offline-bd";
        case 1:
            return "safe-bd";
        case 2:
            return "warning-bd";
        case 3:
            return "danger-bd";
        case 4:
            return "fault-bd";
        default:
            break;
    }
    return "";

}